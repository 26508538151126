import '../stylesheets/application.scss'
import "@fortawesome/fontawesome-free/css/all.css"

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import 'jquery/dist/jquery'

import './bootstrap_custom.js'
import 'admin-lte/dist/js/adminlte'

import 'select2/dist/js/select2'
import 'select2/dist/js/i18n/pt-BR'

import 'bootstrap-table/dist/bootstrap-table'
import 'bootstrap-table/dist/locale/bootstrap-table-pt-BR'

import './fileinput'

import 'bootstrap-fileinput/js/locales/pt-BR'
import 'bootstrap-fileinput/themes/fas/theme'

require("@nathanvda/cocoon")

import Inputmask from "inputmask"

import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'

document.addEventListener('DOMContentLoaded', () => {
  Inputmask("999\.999\.999\-99").mask('.cpf_mask')
  Inputmask("(99\.9\.999999999\-9)|(9999999\-99\.9999\.8\.18\.9999)").mask('.procnumber')

  $('#btnToggle').click(() => {
    $('#navbarCollapse').toggle(1000)
  })
  
  $('.bootstrap-table-mobile').bootstrapTable({
    mobileResponsive: true,
    checkOnInit: true,
    classes: 'table table-striped table-hover table-bordered table-borderless'
  })

  $('[data-toggle="tooltip"]').tooltip()

  if (document.querySelector('#btn_filter') !== null) {
    document.querySelector('#btn_filter').addEventListener('click', (event) => {
      console.log(event.target)
      if (event.target.querySelector("i").classList.contains('fa-angle-right')) {
        event.target.querySelector("i").classList.remove('fa-angle-right')
        event.target.querySelector("i").classList.add('fa-angle-down')
        event.target.closest('.card').querySelector(".card-body").style.display = "block"
      } else {
        event.target.querySelector("i").classList.remove('fa-angle-down')
        event.target.querySelector("i").classList.add('fa-angle-right')
        event.target.closest('.card').querySelector(".card-body").style.display = "none"
      }
    })
  }

  var $input = $('.fileinput')
  if ($input.length) {
    $input.fileinput({
      theme: 'fas',
      language: 'pt-BR',
      'previewFileType': 'any',
      initialPreviewAsData: true,
      initialPreview: $input.data('logo'),
      initialPreviewConfig: [
        { caption: $input.data('logoname'), height: 'auto', widht: '120px', showRemove: false, showDrag: false, key: 1 }
      ],
      allowedFileExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
      showRemove: false,
      showClose: false,
      showUpload: false
    })
  }

  set_select2()
  set_select2_cities()

  $('#inscriptions').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
    set_select2()
  })

  $('#phases').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
    $(this).find('.groups').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
      if (insertedItem.get(0).classList.contains("group-card")) {
        $('.add_all_teams').click(function () {
          let btn = this.parentElement.parentElement.parentElement.querySelector('.add_fields')
          $(this).data('teams').forEach(function(value) {
            btn.click()
            let selects = btn.parentElement.parentElement.parentElement.parentElement.querySelectorAll('select')
            let last_select = selects[selects.length - 1]
            last_select.value = value
          })
          return false
        })

        $('.add_qtde_rounds').click(function () {
          console.log(this)
          let btn = this.parentElement.parentElement.parentElement.querySelector('.add_fields')
          let qtde = this.parentElement.parentElement.querySelector('input').value
          console.log(qtde)
          for (let i = 0; i < qtde; i++) {
            btn.click()
            let inputs = btn.parentElement.parentElement.parentElement.parentElement.querySelectorAll('input[id$=_number]')
            let last_input = inputs[inputs.length - 1]
            last_input.value = i + 1
          }
          return false
        })
      }
    })
  })

  $('#attendance_client').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
    $('.attendance_customer').hide()
    $('#attendance_customer_id').val(null).trigger('change')
    $("#attendance_new_client").hide()
    Inputmask("999\.999\.999\-99").mask('.cpf_mask')
  });
  
  $('#attendance_client').on('cocoon:after-remove', function (e, insertedItem, originalEvent) {
    $('.attendance_customer').show()
    $("#attendance_new_client").show()
  })
  
  $('#attendance_unit_id').on('select2:select', function (e) {
    get_possible_times()
  })

  if (document.querySelector('#attendance_moment_date') !== null) {
    document.getElementById('attendance_moment_date').addEventListener('blur', (event) => {
      get_possible_times()
    })
  }

  var calendarEl = document.getElementById('unit_calendar')
  let calendar

  if (calendarEl) {
    calendar = new Calendar(calendarEl, {
      plugins: [timeGridPlugin, dayGridPlugin, bootstrapPlugin],
      defaultView: 'timeGridWeek',
      themeSystem: 'bootstrap',
      allDaySlot: false,
      slotEventOverlap: false,
      slotDuration: '00:30:00',
      weekends: false,
      scrollTime: '08:00:00',
      slotDuration: '00:10:00',
      minTime: "08:00:00",
      maxTime: "14:00:00",
      nowIndicator: true,
      locale: ptbrLocale,
      header: {
        left: 'today prev,next',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title'
      },
      events: '/units/' + calendarEl.dataset.unitid + '/schedule'
    })

    calendar.render()
  }
})

function get_possible_times() {
  let unit = document.querySelector('#attendance_unit_id').value
  let the_date = document.querySelector('#attendance_moment_date').value
  if (unit != "" && the_date != "") {
    $.ajax({
      url: "/units/" + unit + "?the_date=" + the_date,
      dataType: "json",
      beforeSend: function () {
        document.getElementById('submit_attendance').disabled = true
      }
    }).done(function (data) {
      if (data.possible_times.data.length == 0) {
        alert(data.possible_times.msg)
      }
      let select = document.querySelector('#attendance_moment_hour')
      select.innerHTML = ""
      $.each(data.possible_times.data, function (index, value) {
        let option = new Option(value, value, true, true)
        select.append(option)
      })
      document.getElementById('submit_attendance').disabled = false
    })
  }
}

function set_select2() {
  $(".select2").select2({
    language: "pt-BR",
    theme: "bootstrap4"
  })

  $(".select2-tags").select2({
    language: "pt-BR",
    theme: "bootstrap4",
    tags: true,
    tokenSeparators: [',']
  })

  $(".select2-img").select2({
    language: "pt-BR",
    theme: "bootstrap4"
  })
}

function set_select2_cities() {
  $(".select2_cities").select2({
    placeholder: "Buscar Cidade",
    minimumInputLength: 3,
    language: "pt-BR",
    theme: "bootstrap4",
    ajax: {
      dataType: 'json',
      url: "/utils/cities",
      processResults: function (data) {
        return {
          results: data
        };
      }
    }
  });
}
